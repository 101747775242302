var allWords = ['iOS', 'Web', 'iPadOS', 'visionOS', 'Android'];
var tEl = document.querySelector('.typing');
if(tEl){

    const time_before_writing_new_word = 1500;
    const time_before_restart = 500;
    const time_to_remove_each_letter = 50;
    const time_to_write_new_letter = 100;

    loopWords(allWords);

    function loopWords(words) {
        writeWords(words.slice(), function () {
            window.setTimeout(function () {
                loopWords(words);
            }, time_before_restart);
        });
    }

    function writeWords(words, done) {
        if (done == null) { done = function () {}; }
        if (words.length === 0) { done(); return; }

        var word = words.shift();
        //
        writeWord(word, function () {	window.setTimeout(function () { 	writeWords(words, done); }, time_before_writing_new_word); });
    }

    function writeWord(word, done, amount) {
        let part = tEl.innerText;
        let delay = time_to_write_new_letter;

        if (word === part) { done(word); return; }

        if (word.indexOf(part) !== 0 && part !== '') {
            tEl.innerText = part.substr(0, part.length - 1);
            delay = time_to_remove_each_letter;
        } else {
            if (part === '') { amount = 1; }
            tEl.innerText = word.substr(0, amount);
        }
        // Lettre une par une
        window.setTimeout(function () {
            writeWord(word, done, ++amount);
        }, delay);
    }
}





